import React from 'react';


const NetworkStatusOverlay = ({ isOnline, retryAction }) => {
  if (isOnline) return null;

  return (
    <>
    
    <div className='fixed top-0 left-0 right-0 bottom-0 bg-white-A700_01 text-gray-700 flex items-center justify-center  z-[40]'>
    
      <div className='text-center'>
        <h1 className='text-2xl'>Something went wrong, please check your internet connection.</h1>
        <button className='px-6 py-4 rounded-md text-lg mt-4 bg-blue-500 text-white-A700_01' onClick={retryAction}>Try Again</button>
      </div>
    </div>
    </>
  );
};




export default NetworkStatusOverlay;
