import React from "react";

const sizeClasses = {
  txtRubikBold317: "font-bold font-rubik",
  txtRubikRomanRegular20Gray60004: "font-normal font-rubik",
  txtRubikRegular14WhiteA700: "font-normal font-rubik",
  txtRubikRomanRegular17Gray60004: "font-normal font-rubik",
  txtRubikRegular18Black900: "font-normal font-rubik",
  txtRubikRomanRegular17Blue600: "font-normal font-rubik",
  txtRubikRegular14Black90066: "font-normal font-rubik",
  txtRubikRegular16Blue600: "font-normal font-rubik",
  txtRubikRegular17: "font-normal font-rubik",
  txtPoppinsRegular14: "font-normal font-poppins",
  txtRubikRegular16: "font-normal font-rubik",
  txtRubikRomanMedium18Blue600: "font-medium font-rubik",
  txtRubikRegular18: "font-normal font-rubik",
  txtRubikMedium18Blue600: "font-medium font-rubik",
  txtRubikRomanMedium12: "font-medium font-rubik",
  txtRubikRegular15: "font-normal font-rubik",
  txtRubikRegular16Gray600: "font-normal font-rubik",
  txtRubikRegular14: "font-normal font-rubik",
  txtRubikRomanMedium14: "font-medium font-rubik",
  txtRubikRomanRegular19: "font-normal font-rubik",
  txtRubikRegular16Gray800: "font-normal font-rubik",
  txtRubikRomanRegular17: "font-normal font-rubik",
  txtRubikRomanRegular16: "font-normal font-rubik",
  txtRubikRomanMedium18: "font-medium font-rubik",
  txtRobotoRegular16Blue700: "font-normal font-roboto",
  txtRobotoRegular16Gray700: "font-normal font-roboto",
  txtRubikRomanRegular12: "font-normal font-rubik",
  txtRubikRomanRegular20: "font-normal font-rubik",
  txtRubikRomanSemiBold18: "font-rubik font-semibold",
  txtRubikRomanLight45: "font-light font-rubik",
  txtRubikRomanLight42: "font-light font-rubik",
  txtRubikRomanMedium18Gray900: "font-medium font-rubik",
  txtRubikRomanMedium30: "font-medium font-rubik",
  txtRubikRegular24: "font-normal font-rubik",
  txtPoppinsMedium14: "font-medium font-poppins",
  txtRubikRomanBold40: "font-bold font-rubik",
  txtRubikRomanMedium22: "font-medium font-rubik",
  txtRubikRomanRegular20Blue600: "font-normal font-rubik",
  txtRubikRegular25: "font-normal font-rubik",
  txtPoppinsMedium12: "font-medium font-poppins",
  txtRubikMedium16: "font-medium font-rubik",
  txtRubikRomanRegular23: "font-normal font-rubik",
  txtRubikMedium18: "font-medium font-rubik",
  txtRubikLight42: "font-light font-rubik",
  txtRubikRomanRegular22: "font-normal font-rubik",
  txtRubikRomanLight42Blue600: "font-light font-rubik",
  txtRubikRomanRegular16Gray60004: "font-normal font-rubik",
  txtRubikRomanRegular22Gray60004: "font-normal font-rubik",
  txtRubikRegular15Blue600: "font-normal font-rubik",
  txtRubikBold15: "font-bold font-rubik",
  txtRubikRomanMedium30Gray60004: "font-medium font-rubik",
  txtRubikRegular16Gray60004: "font-normal font-rubik",
  txtRubikRomanRegular16Gray800: "font-normal font-rubik",
  txtRubikRegular16Gray60003: "font-normal font-rubik",
  txtRubikRegular14Bluegray200: "font-normal font-rubik",
  txtRubikPuddlesRegular12: "font-normal font-rubikpuddles",
  txtRubikMedium317: "font-medium font-rubik",
  txtRobotoRegular12: "font-normal font-roboto",
  txtRobotoRegular13: "font-normal font-roboto",
  txtRubikRegular16Gray500: "font-normal font-rubik",
  txtRobotoRegular15: "font-normal font-roboto",
  txtRubikRegular16Gray900: "font-normal font-rubik",
  txtRobotoRegular16: "font-normal font-roboto",
  txtRubikLight16: "font-light font-rubik",
  txtRubikRomanRegular36: "font-normal font-rubik",
  txtRubikRomanMedium18Gray60004: "font-medium font-rubik",
  txtRobotoRegular16Gray800: "font-normal font-roboto",
};

const Text = ({ children, className = "", size, as, ...restProps }) => {
  const Component = as || "p";

  return (
    <Component
      className={`text-left ${className} ${size && sizeClasses[size]}`}
      {...restProps}
    >
      {children}
    </Component>
  );
};

export { Text };
