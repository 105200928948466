
import { useState, useEffect } from 'react';
import axios from 'axios';

const useCompleteRegistration = () => {
  const [isAdditionalinfoset, setisAdditionalinfoset] = useState(false);
 
  const [number, setNumber] = useState(null);
  const [dob, setDob] = useState(null);
  const [gender, setGender] = useState(null);

 
    
  useEffect(() => {
    // Function to fetch profile data from backend
    const fetchProfileData = async () => {
      try {
        const response = await axios.get("/profile");
        // Set the profile data in state
        setNumber(response.data.phoneNo);
        setDob(response.data.dob);
        setGender(response.data.gender);

        if (!response.data.phoneNo && !response.data.dob && !response.data.gender) {
          setisAdditionalinfoset(true);
        }
       
      } catch (error) {
        console.error("Error fetching profile data:", error);
      }
    };
    
    // Call the fetchProfileData function when the component mounts
    fetchProfileData();
  }, []); // Empty dependency array ensures the effect runs only once

   


  return isAdditionalinfoset;
};

export default useCompleteRegistration;
