import React from "react";
import "./styles/color.css";
import "./styles/font.css";
import ReactDOM from "react-dom";
import App from "./App";
import "./styles/index.css";
import "./styles/tailwind.css";
import "mapbox-gl/dist/mapbox-gl.css";
import '@mapbox/mapbox-gl-directions/dist/mapbox-gl-directions.css';
import 'react-datepicker/dist/react-datepicker.css'
import { GoogleOAuthProvider } from '@react-oauth/google';
ReactDOM.render(
  <React.StrictMode>
      <GoogleOAuthProvider clientId="816433245540-1bi21s9ereepi3o06td9pgiek1afggml.apps.googleusercontent.com">
               <App />
    </GoogleOAuthProvider>
  </React.StrictMode>,
  document.getElementById("root"),
);
