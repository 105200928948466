import React, { useEffect, useState } from 'react';
import CookieConsent from 'react-cookie-consent';

const CookieConsentLayout = () => {
  const [hasConsent, setHasConsent] = useState(false);

  // Check if user has already given consent when they visit the site
  useEffect(() => {
    const consent = document.cookie.includes("cookie-consent=true");
    if (consent) {
      setHasConsent(true);  // User has accepted cookies
    }
  }, []);

  const handleAccept = () => {
    // Set a cookie to track that the user has accepted cookies
    document.cookie = "cookie-consent=true; path=/; max-age=31536000; secure; SameSite=Strict";
    setHasConsent(true);
  };

  const handleDecline = () => {
    // Optionally, you can store the "decline" in a cookie to prevent re-prompting
    document.cookie = "cookie-consent=false; path=/; max-age=31536000; secure; SameSite=Strict";
    console.log("You cannot log in without accepting cookies.");
  };

  // Block login or other actions until consent is given
  if (!hasConsent) {
    return (
      <div>
        <CookieConsent
          location="bottom"
          buttonText="Accept"
          declineButtonText="Decline"
          cookieName="cookie-consent"
          style={{ background: "#3B82F6" }}
          buttonStyle={{
            background: "#1D4ED8",
            color: "#ffffff",
            padding: "0.5rem 1rem",
            borderRadius: "0.25rem"
          }}
          declineButtonStyle={{
            background: "#EF4444",
            color: "#ffffff",
            padding: "0.5rem 1rem",
            borderRadius: "0.25rem"
          }}
          onAccept={handleAccept}
          onDecline={handleDecline}
          enableDeclineButton
        >
          <span className="text-sm">
            We use cookies to improve your experience. By accepting, you agree to our use of cookies.{" "}
            <a href="/privacypolicy" className="text-blue-100 underline hover:text-blue-300 transition">
              Learn more
            </a>
          </span>
        </CookieConsent>
      </div>
    );
  }

  // Normal app behavior when cookies are accepted
  return (
    <></>
  );
};

export default CookieConsentLayout;
