import React from "react";
import HeaderOv from "components/HeaderOv";
import Footer from "components/Footer";
import { Text,Img, Line } from "components";
import { Link } from "react-router-dom";

const NotFound = () => {
  return (
    <div className="bg-white-A700 flex flex-col font-rubik items-center justify-start mx-auto w-full h-full">
    <HeaderOv className="bg-white-A700 flex md:flex-col md:gap-5 items-center justify-center md:px-5 rounded w-full" />
    <div className="flex flex-col items-center mt-[129px] justify-center w-full h-full">
      <div className="flex flex-col items-center justify-center w-full h-full">
        <Img src="/images/notfoundpage.svg" className="w-80 h-80" />
        <Text className="text-3xl  text-gray-800">Page Not Found</Text>
        <Link to="/" className="flex items-center justify-center mt-5">
          <Text className="text-lg text-blue-600">Back to Home</Text>
        </Link>
      </div>
    </div>
    <Line className="bg-gray-200 h-px w-full mt-2" />
    <Footer className="bg-white-A700 flex items-center  justify-center md:px-5 w-full" />
  </div>
  );
};

export default NotFound;
