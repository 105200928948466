import React,{useEffect} from "react";
import Routes from "./Routes";
import { AuthProvider } from "contexts/AuthContext";
import { ToastProvider } from "contexts/ToastContext";

function App() {
  
  useEffect(() => {
    window.fbAsyncInit = function() {
      window.FB.init({
        appId     : '515500661249071', // Replace with your Facebook app ID
        cookie     : true,
        xfbml      : true,
        version    : 'v20.0'
      });
        
      window.FB.AppEvents.logPageView();   
    };
    // Load the SDK asynchronously
(function(d, s, id){
  var js, fjs = d.getElementsByTagName(s)[0];
  if (d.getElementById(id)) {return;}
  js = d.createElement(s); js.id = id;
  js.src = "https://connect.facebook.net/en_US/sdk.js";
  fjs.parentNode.insertBefore(js, fjs);
}(document, 'script', 'facebook-jssdk'));
  }, []);
  return  (
    
    <AuthProvider>
      <ToastProvider>
      <Routes />
      </ToastProvider>
    </AuthProvider>
    
  );
}


export default App;
