import { createContext } from 'react';
import { useState } from 'react';

export const OfferrideContext = createContext();

export const OfferrideProvider = ({ children }) => {
  const [formStatus, setFormStatus] = useState({
    offerride: false,
    frameFourteen: false,
    postride: false,
  });
  const [startLocation, setStartLocation] = useState(null);
  const [endLocation, setEndLocation] = useState(null);

  return (
    <OfferrideContext.Provider value={{ formStatus, setFormStatus, startLocation,  setStartLocation,  endLocation, setEndLocation}}>
      {children}
    </OfferrideContext.Provider>
  );
};