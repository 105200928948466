import React from "react";
import { Link } from "react-router-dom";
import { Img, Text } from "components";

const Footer = (props) => {
  return (
    <>
      <footer className={props.className}>
      <footer className="bg-white font-rubik">
  <div className="mx-auto max-w-screen sm:px-4 py-16 md:px-6 px-8">
    <div className="flex items-start gap-8">
      

      <div className="sm:mt-8 grid sm:grid-cols-2 gap-x-16  mt-0 grid-cols-12 gap-y-16">
        

       

        <div className="col-span-3 sm:col-span-1">
          <p className="font-medium sm:font-normal text-gray-900 text-[15px]">JoinMiCar</p>

          <ul className="mt-6 space-y-4 text-sm">
            <li>
              <a href="#" className="text-gray-700 transition hover:opacity-75"> How It Works </a>
            </li>

            <li>
              <a href="#" className="text-gray-700 transition hover:opacity-75"> Ratings </a>
            </li>

            <li>
              <a href="#" className="text-gray-700 transition hover:opacity-75"> Trust and Safety </a>
            </li>

            <li>
              <a href="#" className="text-gray-700 transition hover:opacity-75"> Member Agreement </a>
            </li>

            <li>
              <a href="#" className="text-gray-700 transition hover:opacity-75"> Insurance </a>
            </li>
          </ul>
        </div>

        <div className="col-span-3 sm:col-span-1">
          <p className="font-medium sm:font-normal text-gray-900 text-[16px]">Company</p>

          <ul className="mt-6 space-y-4 text-sm">
            <li>
              <a href="#" className="text-gray-700 transition hover:opacity-75"> About us </a>
            </li>

            <li>
              <Link to='/faq' className="text-gray-700 transition hover:opacity-75"> FAQs </Link>
            </li>

            <li>
              <a href="#" className="text-gray-700 transition hover:opacity-75"> Location </a>
            </li>
          </ul>
        </div>

        <div className="col-span-3 sm:col-span-1">
          <p className="font-medium sm:font-normal text-gray-900 text-[16px]">Helpful Links</p>

          <ul className="mt-6 space-y-4 text-sm">
            <li>
              <Link to='/contactus' className="text-gray-700 transition hover:opacity-75"> Contact us </Link>
            </li>

            <li>
              <a href="#" className="text-gray-700 transition hover:opacity-75">  </a>
            </li>

            <li>
              <a href="#" className="text-gray-700 transition hover:opacity-75">  </a>
            </li>
          </ul>
        </div>

     


        <ul className="col-span-3 gap-6 ">
          <li>
            <a
              href="https://www.facebook.com/joinmicar"
              rel="noreferrer"
              target="_blank"
              className="text-gray-700 transition hover:opacity-75"
            >
              <span className="sr-only">Facebook</span>

              <Img  src="/images/facebook.svg" alt="facebook.com link" />
            </a>
          </li>

          <li>
          <a
  href="https://www.linkedin.com/company/joinmicar"
  rel="noreferrer"
  target="_blank"
  className="text-gray-700 transition hover:opacity-75"
>
  <span className="sr-only">LinkedIn</span>
  <Img  src="/images/linkedin.svg" alt="linkedin link" /> 
  
</a>

          </li>

          <li>
            <a
              href="https://x.com/joinmicar"
              rel="noreferrer"
              target="_blank"
              className="text-gray-700 transition hover:opacity-75"
            >
              <span className="sr-only">Twitter</span>

              <Img  src="/images/x.svg" alt="x.com link" />
            </a>
          </li>



        </ul>
      </div>
    </div>

    <div className="mt-8 border-t border-gray-100 pt-8">
      <div className="sm:flex sm:justify-between">
        <p className="text-xs text-gray-500">&copy; 2023. JoinMiCar. All rights reserved.</p>

        <ul className="mt-8 flex flex-wrap justify-start gap-4 text-xs sm:mt-0 lg:justify-end">
          <li>
            <a href="#" className="text-gray-500 transition hover:opacity-75"> Terms & Conditions </a>
          </li>

          <li>
            <a href="#" className="text-gray-500 transition hover:opacity-75"> Privacy Policy </a>
          </li>

          <li>
            <a href="#" className="text-gray-500 transition hover:opacity-75"> Cookies </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</footer>
        
      </footer>
    </>
  );
};

Footer.defaultProps = {};

export default Footer;
