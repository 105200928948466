// AuthContext.js
import React, { createContext, useState, useEffect } from 'react';
import axios from 'axios';
export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(true); // Initialize loading state
  const [isRedirectedFromSignup, setIsRedirectedFromSignup] = useState(false);

  const checkAuthentication = async () => {
    try {
      localStorage.removeItem('isAuthenticated');
      const cachedAuthStatus = sessionStorage.getItem('isAuthenticated');
      if (cachedAuthStatus !== null) {
        setIsAuthenticated(JSON.parse(cachedAuthStatus));
        setIsLoading(false);
        return;
      }

      const response = await axios.get('/check-access-token');
      console.log('Response:', response);
      setIsAuthenticated(response.data.message === 'Sign-in successful');
      sessionStorage.setItem('isAuthenticated', JSON.stringify(response.data.message === 'Sign-in successful'));
    } catch (error) {
      console.error('Error checking authentication:', error);
      setIsAuthenticated(false);
    } finally {
      setIsLoading(false);
    }
  }

useEffect(() => {
  // Fetch the authentication status when the component mounts
  checkAuthentication();
}, []);

const refreshAuthentication = async () => {
  // Clear cached isAuthenticated status
  sessionStorage.removeItem('isAuthenticated');
  setIsAuthenticated(false);
    // Trigger authentication check again
    setIsLoading(true);
    await checkAuthentication();
  };

  return (
    <AuthContext.Provider value={{ isAuthenticated, isLoading,setIsLoading, setIsAuthenticated, isRedirectedFromSignup, setIsRedirectedFromSignup, refreshAuthentication  }}>
      {children}
    </AuthContext.Provider>
  );
};
