import React,{useEffect, useContext} from "react";
import Header from "components/Header";
import Header1 from "components/Header1";
import { AuthContext } from "contexts/AuthContext";
const HeaderOv = (props) => {
    
    const { isAuthenticated, isLoading } = useContext(AuthContext);
    
    useEffect(() => {
        // This effect will trigger whenever isAuthenticated changes
        // Add any additional logic here
    }, [isAuthenticated]);

    const getCachedAuthStatus = () => {
        const cachedStatus =  sessionStorage.getItem('isAuthenticated');
        return cachedStatus !== null && JSON.parse(cachedStatus);
      };
    
    return (
        <div className={props.className}>
    {isAuthenticated || getCachedAuthStatus() ? <Header />: <Header1 />  }
    </div>
    )
}
export default HeaderOv;