// Create a new context file, for example, ToastContext.js
import React, { createContext, useState, useContext } from 'react';

const ToastContext = createContext();

export const ToastProvider = ({ children }) => {
  const [showToast, setShowToast] = useState(false);

  const toggleToast = () => {
    setShowToast(true);
  };

  const closeToast = () => {
    setShowToast(false);
  };

  return (
    <ToastContext.Provider value={{ showToast, setShowToast, toggleToast, closeToast }}>
      {children}
    </ToastContext.Provider>
  );
};

export const useToast = () => {
  return useContext(ToastContext);
};
