import React,{useState} from "react";
import { Link } from "react-router-dom";
import { Img, Text, Button } from "components";

const Header1 = (props) => {

  const [showMenu, setShowMenu] = useState(false);

  return (
    <>
       
      <header className="fixed z-50  md:pt-0    top-0 bg-white-A700 flex justify-between md:flex-col sm:h-[100px] sm:px-5   px-24 py-2.5 sm:mx-0 sm:py-0   md:h-auto  md:justify-center  sm:flex-col flex-row sm:gap-5 items-center md:px-5  rounded w-full">
             <div className="flex  sm:justify-between md:justify-center py-[10px]  sm:mt-0 md:mt-10 mt-0 sm:w-full "> 
             <div>
            <Text
              className="mb-0  md:mb-0 sm:ml-0 md:ml-0 font-[450]  sm:text-[27.7px] md:text-[29.7px] text-[24px] text-blue-700"
            
            >
              <Link to='/'>
              JoinMiCar</Link>
            </Text>
             </div>
             {showMenu ? (
            <svg onClick={() => setShowMenu(false)}
              id="close"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="w-7 h-7 sm:mr-0 cursor-pointer sm:flex hidden"
            >
              <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
            </svg>
          ) : (
            <svg onClick={() => setShowMenu(true)}
              id="burger"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="w-7 h-7 sm:mr-0 cursor-pointer sm:flex hidden"
            >
              <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
            </svg>
)}
            </div>
            {showMenu && (
        <div onClick={() => setShowMenu(false)} className="fixed inset-0 bg-black-900 bg-opacity-50  z-[50]"></div>
      )}
            <div className={` ${showMenu ? '' : 'sm:hidden'}   sm:h-[100vh]   sm:fixed sm:top-0 sm:right-0 sm:z-[70] sm:w-[70%] py-[10px] sm:bg-white-A700 sm:shadow-lg sm:transition-transform sm:duration-300    flex  flex-row sm:flex-col  md:flex  ` }>
            
            <Link to='/findaride' className="sm:mt-11"><Text
              className="mb-0 sm:ml-4 ml-[px] sm:text-base md:mt-6 md:mb-0 sm:mt-0 mt-2.5 text-gray-800 text-sm sm:normal-case uppercase"
              size="txtRubikRegular14"
            >
              Find a Ride
            </Text></Link>
            <Link to='/offeraride'>
            <Text
              className="mb-0 sm:ml-4 ml-[49px] sm:text-base md:mt-6 md:mb-0  sm:mt-3 mt-2.5 text-gray-800 text-sm sm:normal-case uppercase"
              size="txtRubikRegular14"
            >
              Offer a Ride
            </Text></Link>
           
            <Link to='/contactus' 
            >
              <Text className="mb-0 sm:ml-4 ml-[49px] sm:text-base md:mt-6 md:mb-0  sm:mt-3 mt-2.5 text-gray-800 text-sm sm:normal-case uppercase" size="txtRubikRegular14">
                Contact Us</Text>
            </Link>
            <Link to='/signinpage' 
              
          
            >
              <Text className="mb-0 sm:ml-4 ml-[49px] sm:text-base md:mt-6 md:mb-0  sm:mt-3 mt-2.5 text-gray-800 text-sm sm:normal-case uppercase" size="txtRubikRegular14">Sign in</Text>
            </Link>
             <Link to='/signuppage' className="hidden sm:flex  sm:ml-4    sm:mt-3  text-gray-800  sm:normal-case uppercase" >
             <Text className=" sm:text-base">Sign up</Text>
             </Link>
             <Link to='/signuppage' 
             className="sm:w-full border-[1.5px] rounded border-blue-500 px-4  py-[8px]  sm:hidden sm:justify-center mb-0 sm:ml-4 ml-[49px] sm:text-base md:mt-3 md:mb-0  sm:mt-3  text-gray-800 text-sm sm:normal-case uppercase " >
              Sign up
            </Link>
            <div className="hidden sm:flex sm:fixed right-0 sm:top-3">
    <svg onClick={() => setShowMenu(false)}
              id="close"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="w-7 h-7   sm:mr-4 cursor-pointer sm:flex hidden"
            >
              <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
            </svg>
            </div>
            </div>
          </header>
    
    </>
  );
};

Header1.defaultProps = {};

export default Header1;
